import React, { useState, useRef, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';
import screenfull from 'screenfull';
import ReactPlayer from 'react-player';
import { ReactComponent as ArrowSvg } from '../../assets/images/dfb-icon-arrow-dropdown-left-rgb-white.svg'

interface VidProps {
  id: string;
  title: string;
  url: string;
}
interface VideoGalleryProps {
  videos: VidProps[];
}

const usePreloadThumbnails = (videos: VidProps[]) => {
  useEffect(() => {
    videos.forEach((video) => {
      if (video.url.includes('youtube')) {
        const img = new Image();
        img.src = `https://img.youtube.com/vi/${video.id}/mqdefault.jpg`;
      }
    });
  }, [videos]);
};

const VideoGallery: React.FC<VideoGalleryProps> = ({ videos }) => {
  const [activeVideoIndex, setActiveVideoIndex] = useState<number>(0);
  const playerContainerRef = useRef<HTMLDivElement>(null);

  usePreloadThumbnails(videos);
  const handleFullScreen = () => {
    if (screenfull.isEnabled && playerContainerRef.current) {
      screenfull.toggle(playerContainerRef.current);
    }
  };

  if (!videos || videos.length === 0) {
    return <div>No videos available</div>;
  }

  return (
    <div className="video-gallery">
      {/* Video Player with Pagination Controls */}
      <div className="video-player-header">
        <span className="video-counter">
          {activeVideoIndex + 1}/{videos.length}
        </span>
        <button type="button" title="Full Screen" className="fullscreen-button" onClick={handleFullScreen}>
          &#x26F6;
        </button>
      </div>
      <div className="video-player-wrapper">

        <div ref={playerContainerRef} className="react-player-wrapper">
          <ReactPlayer
            url={videos[activeVideoIndex].url}
            controls={true}
            playing={true}
            width="100%"
            height="100%"
            className="react-player"
            config={{
              youtube: {
                playerVars: { modestbranding: 1 },
              },
            }}
          />
        </div>

      </div>
      {/* Swiper for Thumbnails */}
      <div className="swiper-section">
        <button type="button" title="Previous Video" className="swiper-custom-prev">
          <ArrowSvg />
        </button>
        <Swiper
          slidesPerView="auto"
          spaceBetween={15}
          centeredSlides={false}
          loop={false}
          pagination={{ el: '.swiper-pagination', clickable: true }}
          navigation={{
            nextEl: '.swiper-custom-next',
            prevEl: '.swiper-custom-prev',
          }}
          modules={[Navigation, Pagination]}
          className="swiper-container-videos"
        >
          {videos.map((video, index) => (
            <SwiperSlide key={video.id} className="vid-item">
              <button
                type="button"
                onClick={() => setActiveVideoIndex(index)}
                className={index === activeVideoIndex ? 'selected' : ''}
              >
                <span className="vid-thumb">
                  <img
                    src={`https://img.youtube.com/vi/${video.id}/mqdefault.jpg`}
                    alt={`Thumbnail for ${video.title}`}
                    className="thumb"
                  />
                </span>
              </button>
            </SwiperSlide>
          ))}
        </Swiper>
        <button type="button" title="Next Video" className="swiper-custom-next">
          <ArrowSvg />
        </button>
      </div>
    </div>
  );
};

export default VideoGallery;