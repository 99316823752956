import React, { useLayoutEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useNav } from '../../hooks/useNav'
import { useViewport } from '../../hooks/useViewport'

import SectionHeader from '../atom/section-header'
import LogoCard from '../../components/atom/logo-card'
import BlobImage from '../atom/blob-image'
import UEFALogo from '../../assets/images/euro2024.svg'
import BMWKI_NKI from '../../assets/images/bMWK_NKI_logos.svg'
import DOSBLogo from '../../assets/images/dosb-logo-full.svg'
import { gsap, Power4 } from 'gsap';

const SectionTeamplayer = ({id}: { id: string }) => {
  const {t} = useTranslation()
  const ref = useNav(id)
  const bubbleRef = useRef<HTMLDivElement>(null)

  const {width} = useViewport()

  const blobRef = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    const element: HTMLDivElement | null = blobRef.current;
    if (element) {
      gsap.to(
        element.querySelector('.blob__image'),
        {
          y: -30,
          scale: 1.2,
          ease: 'none',
          scrollTrigger: {
            trigger: element,
            markers: false,
            start: 'top center',
            invalidateOnRefresh: true,
            end: 'bottom center',
            scrub: true, //onscroll DJ
          },
        }
      );
    }
  }, []);

  useLayoutEffect(() => {
    const element: HTMLDivElement | null = bubbleRef.current;

    let tl: gsap.core.Timeline;
    if (element) {
      tl = gsap.timeline({
        scrollTrigger: {
          trigger: element,
          markers: false,
          start: 'top 80%',
          end: 'bottom center',
          scrub: false, //onscroll DJ
          invalidateOnRefresh: true
        },
      });

      tl.fromTo(
        element.querySelectorAll('a'),
        {
          opacity: 0,
          y: 40,
        },
        {
          y: 0,
          opacity: 1,
          duration: 0.7,
          stagger: 0.2,
          ease: Power4.easeInOut,
        }
      )

    }
    return () => tl?.scrollTrigger?.kill()
  }, []);

  return (
    <div className='section section-teamplayer' id={id} ref={ref}>
      <div className='grid-wrapper'>
        <div className='grid-container'>
          <div className='grid-cell--half grid-offset-pre--five'>
            <SectionHeader
              title={t('teamplayer.intro.title') as string}
              subtitle={t('teamplayer.intro.headline') as string}
              text={t('teamplayer.intro.copy') as string}
              extendedText={t('teamplayer.intro.copy-extended') as string}
              externalLink={t('teamplayer.intro.link-href') as string}
              linkLabel={t('teamplayer.intro.link-label') as string}
              hasHalfWidthAnimation={true}
            />
          </div>

          <div className='section-teamplayer__content'>
            <div className='section-teamplayer__image' ref={blobRef}>
              <BlobImage/>
            </div>

            <div className='section-teamplayer__logos' ref={bubbleRef}>

              {width < 992 ? (
                <div className='section-teamplayer__logos--outer-flex'>
                  <LogoCard
                    logo={BMWKI_NKI}
                    link={t('teamplayer.logo-links.BMWKI-NKI') as string}
                    className='section-teamplayer__logos--bmwki'
                  />
                  <div className='section-teamplayer__logos--inner-flex'>
                    <LogoCard
                      logo={UEFALogo}
                      link={t('teamplayer.logo-links.UEFA') as string}
                      className='section-teamplayer__logos--uefa'
                    />
                    <LogoCard
                      logo={DOSBLogo}
                      link={t('teamplayer.logo-links.DOSB') as string}
                      className='section-teamplayer__logos--dosb'
                    />
                  </div>

                </div>
              ) : (
                <React.Fragment>
                  <LogoCard
                    logo={UEFALogo}
                    link={t('teamplayer.logo-links.UEFA') as string}
                    className='section-teamplayer__logos--uefa'
                  />
                  <LogoCard
                    logo={BMWKI_NKI}
                    link={t('teamplayer.logo-links.BMWKI-NKI') as string}
                    className='section-teamplayer__logos--bmwki'
                  />
                  <LogoCard
                    logo={DOSBLogo}
                    link={t('teamplayer.logo-links.DOSB') as string}
                    className='section-teamplayer__logos--dosb'
                  />
                </React.Fragment>

              )}


            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SectionTeamplayer
