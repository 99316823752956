import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@dfb/dfb-ui-react/brands/dachmarke'
import TwitterLogo from '../../assets/ui-icons/twitter_Fill.svg'
import FacebookLogo from '../../assets/ui-icons/facebook.svg'
import WhatsappLogo from '../../assets/ui-icons/whatsapp.svg'
import EmailLogo from '../../assets/ui-icons/mail.svg'
import PrintLogo from '../../assets/ui-icons/print.svg'

import { ReactComponent as ShareIcon } from '../../assets/ui-icons/Share.svg'

import { WindowWithDataLayer } from '../../App';

declare const window: WindowWithDataLayer;

const SocialMediaSharing = (props: any) => {
  const { t } = useTranslation()

  const [isShareButtonClicked, setIsShareButtonClicked] = useState(false)

  const onShareClick = () => {
    if (isShareButtonClicked) {
      setIsShareButtonClicked(false)
    } else {
      setIsShareButtonClicked(true)
    }
  }

  const onPrintClick = () => {
    onIconClick()
    window.print()
  }

  const onIconClick = () => {
    setIsShareButtonClicked(false)

  }

  useEffect(() => {
    if (isShareButtonClicked) {
      window.dataLayer.push({
        'event': 'click',
        'lightbox_post': props.title,
        'link_text': 'Teilen',
        'outbound': 'false',
      })
    }
  }, [isShareButtonClicked, props.title])

  return (
    <>
      <div className="social-media">
        <Button label='teilen' className='btn-share' type='primary' icon={ShareIcon} onClick={onShareClick} />
        <div className={`social-media__wrapper ${isShareButtonClicked ? 'social-media__wrapper--show' : ''}`}>
          <a
            onClick={onIconClick}
            className='social-media__wrapper-facebook'
            href={t('social-media-sharing.facebook') as string}
            aria-label=''
            target='_blank'
            rel='noreferrer'>
            <img src={FacebookLogo} alt='Facebook Logo' />
          </a>
          <a
            onClick={onIconClick}
            className='social-media__wrapper-twitter'
            href={t('social-media-sharing.twitter') as string}
            aria-label=''
            target='_blank'
            rel='noreferrer'>
            <img src={TwitterLogo} alt='Twitter Logo' />
          </a>
          <a
            onClick={onIconClick}
            className='social-media__wrapper-whatsapp'
            href={t('social-media-sharing.whatsapp') as string}
            aria-label=''>
            <img src={WhatsappLogo} alt='Whatsapp Logo' />
          </a>
          <a
            onClick={onIconClick}
            className='social-media__wrapper-email'
            href={t('social-media-sharing.email') as string}
            aria-label=''>
            <img src={EmailLogo} alt='' />
          </a>
          <div onClick={onPrintClick} className='social-media__wrapper-print' aria-label=''>
            <img src={PrintLogo} alt='' />
          </div>
        </div>
      </div>
    </>
  )
}

export default SocialMediaSharing
