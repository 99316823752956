import React, { useLayoutEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as DOMPurify from 'dompurify'
import { Anchor, Button } from '@dfb/dfb-ui-react/brands/dachmarke'
import { ReactComponent as info } from '../../assets/ui-icons/info.svg';
import { gsap, Power1 } from 'gsap';

type DataSectionHeader = {
  title?: string
  subtitle?: string
  text?: string
  extendedText?: string
  externalLink?: string
  linkLabel?: string
  className?: string
  hasHalfWidthAnimation?: boolean
  hasContentExtraWidth?: boolean
  isHinweisVisible?: boolean
  onHinweisClick?: () => void
}


const SectionHeader = (props: DataSectionHeader) => {
  const {t} = useTranslation()
  const [isTextVisible, setIsTextVisible] = useState(false)
  const headerRef = useRef<HTMLDivElement>(null);
  const isAnimatedOnScroll = false;

  useLayoutEffect(() => {
    const element: HTMLDivElement | null = headerRef.current;
    if (element) {
      gsap.fromTo(
        element.querySelector('.section-head__title'),
        {
          opacity: 0.05,
          y: -40,
        },
        {
          opacity: 0.2,
          y: 0,
          duration: 0.6,
          ease: Power1.easeOut,
          scrollTrigger: {
            trigger: element,
            markers: false,
            start: 'top 80%',
            end: 'bottom center',
            invalidateOnRefresh: true,
            scrub: isAnimatedOnScroll, //onscroll DJ
          },
        }
      );
    }
  }, [isAnimatedOnScroll]);

  useLayoutEffect(() => {
    const element: HTMLDivElement | null = headerRef.current;
    if (element) {
      gsap.fromTo(
        element.querySelectorAll('.section-head--anim-2'),
        {
          opacity: 0.5,
          y: 40,
        },
        {
          opacity: 1,
          y: 0,
          duration: 0.6,
          ease: Power1.easeOut,
          scrollTrigger: {
            trigger: element,
            markers: false,
            start: 'top 80%',
            end: 'bottom center',
            scrub: isAnimatedOnScroll, //onscroll DJ
          },
        }
      );
    }
  }, [isAnimatedOnScroll]);

  useLayoutEffect(() => {
    const element: HTMLDivElement | null = headerRef.current;
    if (element) {
      gsap.fromTo(
        element.querySelectorAll('.section-head--anim-3'),
        {
          opacity: 0.5,
          y: 40,
        },
        {
          opacity: 1,
          y: 0,
          delay: 0.5,
          duration: 0.6,
          ease: Power1.easeOut,
          scrollTrigger: {
            trigger: element,
            markers: false,
            start: 'top 80%',
            end: 'bottom center',
            scrub: isAnimatedOnScroll, //onscroll DJ
          },
        }
      );
    }
  }, [isAnimatedOnScroll]);

  const onClick = () => {
    setIsTextVisible(!isTextVisible)
  }

  const onButtonClick = () => {
    props.onHinweisClick && props.onHinweisClick()
  }

  return (
    <div ref={headerRef}>
      <h2 className={`section-head__title ${
        props.hasHalfWidthAnimation ? 'section-head__title--anim-half' : 'section-head__title--anim-letter'
      }`}>
        {props.title}
      </h2>
      <div
        className={`section-head__content ${props.hasContentExtraWidth ? 'section-head__content--extraWidth' : ''}`}>

        {/*<Controller>*/}
        {/*  <Scene classToggle='animation-active' triggerElement='#trigger' indicators={true}>*/}
        <div
          className={`section-head__subtitle-container section-head--anim-2 ${props.isHinweisVisible ? 'd-flex-column' : ''}`}>
          <h4 className='section-head__subtitle'>{props.subtitle}</h4>
          {props.isHinweisVisible &&
          <Button type={'secondary'} onClick={onButtonClick}
                  label={'Hinweis'} icon={info}/>
          }
        </div>
        {/*</Scene>*/}
        {/*</Controller>*/}

        {/*<Controller>*/}
        {/*  <Scene classToggle='animation-active' triggerElement='#trigger' indicators={true}>*/}
        <div className={'section-head__text-wrapper section-head--anim-3'}>
          {props.text &&
          props.text.split('{{ABSATZ}}').map((paragraph, index) => (
            <p
              key={index}
              className='section-head__text'
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(paragraph, {USE_PROFILES: {html: true}})
              }}></p>
          ))}
          {props.extendedText &&
          props.extendedText.split('{{ABSATZ}}').map((paragraph, index) => (
            <p
              key={index}
              className={`section-head__extended-text ${
                isTextVisible ? 'section-head__text' : 'section-head__text--hidden'
              }`}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(paragraph, {USE_PROFILES: {html: true}})
              }}></p>
          ))}

          {props.extendedText && (
            <div className='section-head__more' onClick={onClick}>
              <Anchor
                type={'secondary'}
                label={(isTextVisible ? t('common.read-less') : t('common.read-more')) as string}
              />
            </div>
          )}


          {/*<Scene classToggle='animation-active' indicators={true}>*/}
          {!props.extendedText && props.externalLink && (
            <div className='section-head__more'>
              <a
                className={`dfb-Anchor dfb-Anchor--secondary`}
                href={props.externalLink}
                target='_blank'
                rel='noreferrer'>
                        <span
                          className='dfb-Anchor-label'>{props.linkLabel ? props.linkLabel : t('common.read-more')}</span>
              </a>
            </div>
          )}
          {/*</Scene>*/}
        </div>
        {/*</Scene>*/}
        {/*</Controller>*/}
      </div>
      {/*</Timeline>*/}
      {/*</Reveal>*/}
    </div>
  )
}

export default SectionHeader
