import { ReactComponent as ArrowUpRight } from '../../assets/ui-icons/arrow_Up_Right.svg'

//link text button in footer info container to open external links
const LinkButtonAtom = (props: any) => {

  return (
    <>
      {props.isLocalLink ?
        <span
          onClick={props.handleClick}
          className='info__link paragraph-big'>
          {props.title}
          <ArrowUpRight className='info__link--icon paragraph-big'/>
        </span>
        :
        <a
          href={props.href}
          target='_blank'
          rel='noreferrer'
          className='info__link paragraph-big'>
          {props.title}
          <ArrowUpRight className='info__link--icon paragraph-big'/>
        </a>
      }
    </>
  );
}

export default LinkButtonAtom

// LinkButtonAtom.propTypes = {
//   title: PropTypes.string,
//   href: PropTypes.string,
//   className: PropTypes.string,
//   handleClick: PropTypes.func,
// }
