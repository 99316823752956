import React, { useEffect, useRef, useState } from 'react'
import {
  Button,
  Checkbox,
  DatePicker,
  Dropdown,
  Input,
  Modal,
  ModalContent,
  ModalFooter,
  RadioButtonGroup
} from '@dfb/dfb-ui-react/brands/dachmarke'
import MultipleDropdown from '../atom/multiple-dropdown';
import axios from 'axios';
import { WidgetInstance } from 'friendly-challenge';

interface ModalFormularProps {
  visible: boolean;
  onClose: () => void;
}

interface FormValues {
  category: string;
  name: string;
  date: string;
  region: string;
  regions: Array<string>;
  projectUrl: string;
  optionalName: string;
  optionalOrgName: string,
  email: string;
  isDataPrivacyAccepted: boolean;
  isNotificationAccepted: boolean;
  solution?: string;
}

export const ModalFormular: React.FC<ModalFormularProps> = ({ visible, onClose }) => {
  const [captchaSolution, setCaptchaSolution] = useState('')
  const [isModalVisible, setIsModalVisible] = useState(visible);
  const [errors, setErrors] = useState({} as any);
  const [isRadioMultipleDisabled, setIsRadioMultipleDisabled] = useState(true);
  const [isSucceeded, setIsSucceeded] = useState(false);
  const [formValues, setFormValues] = useState<FormValues>({
    category: '',
    name: '',
    date: '',
    region: '',
    regions: [],
    projectUrl: '',
    optionalName: '',
    optionalOrgName: '',
    email: '',
    isDataPrivacyAccepted: false,
    isNotificationAccepted: false,
  });
  const radioValues = [
    { value: 'bundesweit', label: 'Bundesweit' },
    { value: 'bundesland', label: 'Bundesland (mehrere Auswahlmöglichkeiten)' }
  ];

  const dropdownCategories = [
    { value: 'Energie', label: 'Energie' },
    { value: 'Wasser', label: 'Wasser' },
    { value: 'Mobilität', label: 'Mobilität' },
    { value: 'Konsum', label: 'Konsum' },
    { value: 'Sonstiges', label: 'Sonstiges' }
  ];

  const dropdownBundeslaender = [
    { value: 'baden-wuerttemberg', label: 'Baden-Württemberg' },
    { value: 'bayern', label: 'Bayern' },
    { value: 'berlin', label: 'Berlin' },
    { value: 'brandenburg', label: 'Brandenburg' },
    { value: 'bremen', label: 'Bremen' },
    { value: 'hamburg', label: 'Hamburg' },
    { value: 'hessen', label: 'Hessen' },
    { value: 'mecklenburg-vorpommern', label: 'Mecklenburg-Vorpommern' },
    { value: 'niedersachsen', label: 'Niedersachsen' },
    { value: 'nordrhein-westfalen', label: 'Nordrhein-Westfalen' },
    { value: 'rheinland-pfalz', label: 'Rheinland-Pfalz' },
    { value: 'saarland', label: 'Saarland' },
    { value: 'sachsen', label: 'Sachsen' },
    { value: 'sachsen-anhalt', label: 'Sachsen-Anhalt' },
    { value: 'schleswig-holstein', label: 'Schleswig-Holstein' },
    { value: 'thueringen', label: 'Thüringen' }
  ];

  const containerRef = useRef<HTMLDivElement>(null);
  const widgetRef = useRef<WidgetInstance | null>(null);

  useEffect(() => {
    return () => {
      if (widgetRef.current && visible) {
        widgetRef.current.reset();
      }
    };
  }, [visible]);

  useEffect(() => {
    const doneCallback = (solution: any) => {
      setCaptchaSolution(solution)
    }
    const errorCallback = (err: any) => {
      console.log('There was an error when trying to solve the Captcha:' + err);
    }

    if (visible && !widgetRef.current && containerRef.current) {
      widgetRef.current = new WidgetInstance(containerRef.current, {
        startMode: 'auto',
        doneCallback: doneCallback,
        errorCallback: errorCallback,
        language: 'de',
        sitekey: process.env.REACT_APP_CAPTCHA_SITE_KEY,
      });
    }
  });


  useEffect(() => {
    if (visible) {
      setFormValues({
        category: '',
        name: '',
        date: '',
        region: '',
        regions: [],
        projectUrl: '',
        optionalName: '',
        optionalOrgName: '',
        email: '',
        isDataPrivacyAccepted: false,
        isNotificationAccepted: false,
      });
      setIsSucceeded(false);
      setErrors(false);
    }
    setIsModalVisible(visible);
  }, [visible]);

  const handleInputChange = (id: string, value: string) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [id]: value
    }));
  };

  const handleCategoryChange = (id: string, value: string) => handleInputChange('category', value);

  const handleMultipleChange = (selectedOptions: any) => {
    const selectedValues = selectedOptions.map((option: { value: string }) => option.value);
    setFormValues(prevState => ({
      ...prevState,
      regions: selectedValues
    }));
  };

  const handleDateChange = (id: string, value: string) => handleInputChange('date', value);
  const handleRadioChange = (id: string, value: string) => {
    handleInputChange('region', value);
    if (value === 'bundesland') {
      setIsRadioMultipleDisabled(false)
    } else {
      setIsRadioMultipleDisabled(true);
      validateForm();
    }
  }

  const validateForm = () => {
    const newErrors: any = {};
    if (!formValues.category) newErrors.category = 'Kategorie ist erforderlich';
    if (!formValues.name) newErrors.name = 'Name ist erforderlich';
    if (!formValues.date) newErrors.date = 'Datum ist erforderlich';
    if (!formValues.region) newErrors.region = 'Region ist erforderlich';
    if (formValues.region === 'budesland' && formValues.regions.length === 0) newErrors.regions = 'Region ist erforderlich';
    if (!formValues.projectUrl) newErrors.projectUrl = 'Projekt-Link ist erforderlich';
    return newErrors;
  };

  const handleSubmit = async () => {
    const formErrors = validateForm();
    const headers = {
      'Content-Type': 'application/json',
    };

    if (!widgetRef.current) {
      console.error('Friendly Captcha widget not initialized.');
      return;
    }
    if (Object.keys(formErrors).length === 0) {
      // x-shared-secret header
      try {
        formValues.solution = captchaSolution;
        if (!captchaSolution) {
          console.error('CAPTCHA nicht abgeschlossen.');
          setErrors({ global: 'Bitte warten Sie kurz ab, damit die Verifizierung des CAPTCHA abgeschlossen werden kann.' });
          return;
        }
        const emailResponse = await axios.post(
          `${process.env.REACT_APP_API_URL}/send-email`,
          formValues,
          { headers }
        );
        console.log('Email sent successfully:', emailResponse.data);
        setIsSucceeded(true);
      } catch (error: any) {
        if (error.response && error.response.status === 500) {
          console.error('Server error (500):', error.response.data);
          setIsSucceeded(false);
          setErrors({ global: 'Ein Server Fehler (500) ist aufgetreten. Bitte kontaktieren Sie DFB.' });
        } else {
          console.error('Error verifying CAPTCHA:', error);
          setIsSucceeded(false);
          setErrors({ global: 'Ein CAPTCHA Fehler ist aufgetreten. Bitte versuchen Sie erneut.' });
        }
        // Reload captcha
        widgetRef.current.reset();
      }
    } else {
      setErrors(formErrors);
    }
  };

  return (
    <div className='modal-formular'>
      <Modal visible={isModalVisible} fullSize={false} close={onClose}>
        {isSucceeded ?
          <div className='modal-formular__success'>
            <ModalContent>
              <h4>Vielen Dank für deine Hilfe!</h4>
              <p>
                So leistet ihr einen wichtigen Beitrag für noch mehr Umwelt- und Klimaschutz im Fußball. <br /><br />
                Die Fördermöglichkeit wird nun geprüft und bei der nächsten Aktualisierung veröffentlicht.
              </p>
            </ModalContent>
            <ModalFooter>
              <Button label={'ZURÜCK ZUR SEITE'} type={'primary'} onClick={onClose} />
            </ModalFooter>
          </div>
          :
          <form>
            <ModalContent className='modal-formular__container'>
              <div className='modal-formular__content'>
                <h4 className='modal-formular__content-title h4'>Neue Fordermöglichkeit</h4>
                <div className='modal-formular__input'>
                  <label htmlFor='category'>
                    <Dropdown
                      id='category'
                      label={'Kategorie der Förderung'}
                      options={dropdownCategories}
                      value={formValues.category}
                      errorMessage={errors.category}
                      onChange={handleCategoryChange}
                    />
                  </label>
                  <label htmlFor='name'>
                    <Input
                      type={'text'}
                      label={'Name der Förderung'}
                      id={'name'}
                      value={formValues.name}
                      errorMessage={errors.name}
                      onChange={handleInputChange} />
                  </label>
                  <label htmlFor='date'>
                    <DatePicker
                      id={'date'}
                      value={formValues.date}
                      errorMessage={errors.date}
                      onSubmit={handleDateChange}
                      label={'Einreichungsfrist der möglichen Antragstellung'} />
                  </label>
                  <p>Förderregion:</p>
                  <RadioButtonGroup
                    values={radioValues}
                    name={'region'}
                    errorMessage={errors.region}
                    onChange={handleRadioChange}
                  />

                  <MultipleDropdown
                    options={dropdownBundeslaender}
                    errorMessage={errors.regions}
                    isDisabled={isRadioMultipleDisabled}
                    onChangeCallback={handleMultipleChange}
                    placeholder={'Auswahl Bundesländer'}
                  ></MultipleDropdown>
                  <Input
                    type={'text'}
                    label={'Link zur Förderung (Projekt-URL)'}
                    value={formValues.projectUrl}
                    id={'projectUrl'}
                    errorMessage={errors.projectUrl}
                    onChange={handleInputChange} />
                  <Input
                    type={'text'}
                    label={'Optional: Vorname, Name'}
                    value={formValues.optionalName}
                    id={'optionalName'}
                    onChange={handleInputChange} />
                  <Input
                    type={'text'}
                    label={'Optional: Vereinsname'}
                    value={formValues.optionalOrgName}
                    id={'optionalOrgName'}
                    onChange={handleInputChange} />
                  <Input
                    type={'email'}
                    label={'Optional: E-Mail Adresse'}
                    value={formValues.email}
                    id={'email'}
                    onChange={handleInputChange} />
                  <Checkbox
                    id={'isDataPrivacyAccepted'}
                    label={'Einwilligung zum Datenschutz'}
                    value={formValues.isDataPrivacyAccepted}
                    onChange={handleInputChange} />
                  <p className='datenschutz__info'>Info: Wenn ihr euren Vereinsnamen eintragt und die
                    Datenschutzerklärung akzeptiert, werden wir euren Vereinsnamen bei der jeweiligen Fördermöglichkeit
                    kommunizieren.</p>
                  <Checkbox
                    id={'isNotificationAccepted'}
                    label={'Einwilligung zur Benachrichtigung per Mail'}
                    value={formValues.isNotificationAccepted}
                    onChange={handleInputChange} />
                </div>
              </div>
            </ModalContent>
            <ModalFooter>
              <Button label={'ABBRECHEN'} type={'secondary'} onClick={onClose} />
              <Button label={'ABSCHICKEN'} type={'primary'} onClick={handleSubmit} id='submitBtn' />
              <div id='captcha-container' className='frc-captcha' ref={containerRef}></div>
            </ModalFooter>
            {errors.global && <div className='modal-formular__error'>{errors.global}</div>}

          </form>
        }
      </Modal>
    </div>
  )
}
