import * as DOMPurify from 'dompurify'
import { useEffect } from 'react';
import { Modal, ModalContent, ModalFooter } from '@dfb/dfb-ui-react/brands/dachmarke'
import { ModalInnerHeader } from '../atom/modal-inner-header'
import LinkButtonAtom from '../atom/link-button'
import DownloadAtom from '../atom/download'
import { ReactComponent as Blob } from '../../assets/images/blob-anpfiff.svg'
import CardSlider from '../element/card-slider'
import { SwiperSlide } from 'swiper/react'
import SocialMediaSharing from '../atom/social-media-sharing'
import { WindowWithDataLayer } from '../../App';

declare const window: WindowWithDataLayer;

export const CardContentModal = (props: any) => {

  useEffect(() => {
    if (props.visible) {
      window.dataLayer.push({
        'event': 'page_view',
        'page_category': 'Lightbox',
        'lightbox_post': props.title,
      })
    }
  }, [props.visible, props.title])

  DOMPurify.addHook('afterSanitizeAttributes', function (node) {
    // set all elements owning target to target=_blank
    if ('target' in node) {
      node.setAttribute('target', '_blank')
      node.setAttribute('rel', 'noreferrer')
    }
  })

  return (
    <Modal visible={props.visible} fullSize={props.fullSize} close={props.close}>
      <ModalInnerHeader />

      <ModalContent className='modal'>
        <div className='modal__container'>
          {props.images && (
            <div className='modal__images'>
              <div className={props.images?.length === 1 ? 'modal__no-img-slider' : 'modal__img-slider'}>
                <CardSlider autoplay={props.autoplay ?? 'false'}>
                  {props.images?.length >= 2 &&
                    props.images.map((item: any, index: number) => (
                      <SwiperSlide key={item + index}>
                        <img src={item} alt='modal' />
                      </SwiperSlide>
                    ))}
                </CardSlider>
              </div>
              {props.images?.length === 1 && (
                <div>
                  <img src={props.images[0]} alt={props.headline}></img>
                </div>
              )}
            </div>
          )}
          <div className='modal__left'>
            <div className='modal__subtitle'>
              {props.category && props.category.map((item: string, index: number) => <p key={index}>{item}</p>)}
              {props.cost && (
                <>
                  <p>&middot;</p>
                  <p>{props.cost}</p>
                </>
              )}
            </div>
            {props.lesezeit && <p className='modal__lesezeit'>Lesezeit: {props.lesezeit}</p>}
            <h3 className='h3 modal__title'>{props.title}</h3>
            {props.textLeft &&
              props.textLeft.split('{{ABSATZ}}').map((paragraph: string, index: number) => (
                <p
                  key={index}
                  className='modal__text-padding'
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(paragraph, { USE_PROFILES: { html: true } })
                  }}></p>
              ))}
          </div>

          <div
            className={`${props.images ? 'modal__right modal__right--img-slider' : 'modal__right'} ${props.images?.length === 1 ? 'modal__right--one-image' : ''
              }`}>
            {props.textRight &&
              props.textRight.split('{{ABSATZ}}').map((paragraph: string, index: number) => (
                <p
                  key={index}
                  className='modal__text-padding'
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(paragraph, { USE_PROFILES: { html: true } })
                  }}></p>
              ))}
            {props.merkmale && (
              <div className='modal__merkmale'>
                <p className='paragraph-big color-green f-bold'>{props.merkmale.title}</p>
                {props.merkmale.features.map((item: any, index: number) => (
                  <div key={index} className='modal__merkmale--margin'>
                    <p className='modal__merkmale-title paragraph-small'>{item.feature}</p>
                    {item.content &&
                      item.content.split('{{ABSATZ}}').map((paragraph: string, index: number) => (
                        <p
                          key={index}
                          className='modal__text-padding'
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(paragraph, { USE_PROFILES: { html: true } })
                          }}></p>
                      ))}
                  </div>
                ))}
              </div>
            )}

            {props.extras && props.extras.links && (
              <div className='modal__link'>
                <p className='paragraph-big color-green'>{props.extras.linksTitle}</p>
                {Array.isArray(props.extras.links) &&
                  props.extras.links.map((item: any, index: number) => (
                    <ul key={index} className={props.merkmale ? 'btn-bg-green' : ''}>
                      <p className='modal__link-subtitle paragraph-small'>{item.title}</p>
                      {item.type === 'link' && (
                        <LinkButtonAtom
                          title={item.label}
                          handleClick={() => props.onSectionLink(item.localLink)}
                          isLocalLink={item.localLink ? true : false}
                          href={item.localLink ? '#' + item.localLink : item.url}></LinkButtonAtom>
                      )}
                      {item.type === 'download' && (
                        <DownloadAtom title={item.label} linkref={item.url} massnahmen={true}></DownloadAtom>
                      )}
                      <p>{item.description}</p>
                    </ul>
                  ))}
              </div>
            )}

            {props.merkmale ? <SocialMediaSharing title={props.title} /> : ''}
          </div>
        </div>
      </ModalContent>

      <ModalFooter className='modal__footer'>
        {props.merkmale ? '' : <SocialMediaSharing title={props.title} />}
        <div className='blob-image'>
          <Blob />
        </div>
      </ModalFooter>
    </Modal>
  )
}
