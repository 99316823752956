import { ReactComponent as PdfLogo } from '../../assets/ui-icons/pdf.svg'
import { useTranslation } from 'react-i18next'
import { ReactComponent as DownloadLogo } from '../../assets/ui-icons/download.svg'

const DownloadAtom = (props: any) => {
  const {t} = useTranslation()
  return (
    <div className='download__container'>
      <h6>{props.headline}</h6>
      <a href={props.linkref} target='_blank' rel='noreferrer'>
        {props.title ? props.title : t('common.download')}
        {props.massnahmen === true ? <DownloadLogo/> : <PdfLogo/>}
      </a>
    </div>
  )
}

export default DownloadAtom
