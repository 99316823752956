import React, { createRef, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Lottie, { LottieRef } from 'lottie-react'
import Masonry from 'react-masonry-css'
// import Masonry from 'typescript-react-infinite-masonry';
import { Anchor, Button, Card, CardContent, CardFooter, Dropdown, Modal } from '@dfb/dfb-ui-react/brands/dachmarke'
import { Spinner } from '@dfb/dfb-ui-react/brands/dachmarke/index'
import gelesenLottie from '../../assets/animation/gelesen-hover.json'

import SectionCard from '../atom/section-card'
import SectionHeader from '../atom/section-header'
import { useViewport } from '../../hooks/useViewport'
import categories from '../../data/categories.json'
import costs from '../../data/costs.json'
import sparkReadArticle from '../../assets/animation/spark-read-article.json'
import MassnahmenLoader from '../element/massnahmen-progress'
import { ModalTrophy } from '../element/modal-trophy'
import { ReactComponent as Trophy } from '../../assets/pictograms/Trhopy.svg'
import { ReactComponent as Schuh } from '../../assets/pictograms/technologie_equipment.svg'
import { ReactComponent as Gelesen } from '../../assets/pictograms/Gelesen.svg'
import { ReactComponent as ProgressKnob } from '../../assets/images/knob-progress-bar.svg'
import { CardContentModal } from './card-content-modal'
import { ReactComponent as BlobMassnahmen } from '../../assets/images/blob-massnahmen.svg'
import { gsap, Power1 } from 'gsap'

export interface CardData {
  id: number
  image: string
  headline: string
  description: string
  category: string[]
  cost: string
  link: string
  fullversion: {
    lesezeit: string
    headline: string
    textLeft: string
    images: string[]
    merkmale: {
      title: string
      features: [
        {
          title: string
          content: string
        }
      ]
    }
    extras: {
      links: [
        {
          label: string
          url: string
          descrioption: string
        }
      ]
      downloads: [
        {
          label: string
          url: string
          descrioption: string
        }
      ]
    }
  }
}

const Massnahmen = (props: any) => {
  const { t } = useTranslation()
  // const ref = useNav(props.id)

  const [readCardIds, setReadCardIds] = useState<Array<number>>([])
  const [isLoading, setIsLoading] = useState(true)
  const [selectedCategory, setSelectedCategory] = useState('')
  const [selectedCost, setSelectedCost] = useState('')
  const [gridHasMore, setGridHasMore] = useState(false)
  const [allItems, setAllItems] = useState<CardData[]>([])
  const [filteredItems, setFilteredItems] = useState<CardData[]>([])
  const [visibleItems, setVisibleItems] = useState<CardData[]>([])
  const [addedItemsIteration, setAddedItemsIteration] = useState(1)
  const [isTrophyModalVisible, setIsTrophyModalVisible] = useState(false)
  const [isMassnahmenMaster, setIsMassnahmenMaster] = useState(false)
  const [cardAmount, setCardAmount] = useState(0)
  const [isInfoModalVisible, setIsInfoModalVisible] = useState(false)
  const [isCardModalVisible, setIsCardModalVisible] = useState(false)
  const [cardModalData, setCardModalData] = useState<CardData | null>(null)
  const [currentLottieRef, setCurrentLottieRef] = useState<LottieRef | undefined>(undefined)

  const massnahmenRef: React.RefObject<HTMLDivElement> | undefined = useRef(null)
  const sparkleLottieRef: LottieRef | undefined = useRef(null)
  let gelesenLottieRef = useRef<(LottieRef | undefined)[]>([...Array(50)].map(() => createRef()))
  const sectionCardRef = useRef<HTMLDivElement>(null)

  const { width } = useViewport()
  const isMobile = width < 992
  const breakpointColumnsObj = {
    default: 3,
    1280: 3,
    992: 2,
    576: 1
  }
  const readCounter = readCardIds.length
  const progress = (100 / cardAmount) * readCounter

  const MAXIMUM_CARDS_DESKTOP = 6
  const MAXIMUM_CARDS_MOBILE = 3
  const SELECTED_MAXIMUM = isMobile ? MAXIMUM_CARDS_MOBILE : MAXIMUM_CARDS_DESKTOP

  const excludeCategoriesFromCardDisplay: string[] = (
    t('massnahmen.filter.excludeCategoriesFromCardDisplay', '') as string
  ).split(',')

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    const isMaster = window.localStorage.getItem('isMassnahmenMaster')
    const readCardIds = window.localStorage.getItem('readCardIds')
    setIsLoading(true)

    fetch('/data/cards.json', { signal: signal })
      .then(res => res.json())
      .then(
        result => {
          setCardAmount(result.length)
          let storageCardIds = []
          let storageIsMaster = false

          if (readCardIds !== null) storageCardIds = JSON.parse(readCardIds)

          if (isMaster !== null && JSON.parse(isMaster) === true) {
            storageIsMaster = cardAmount === storageCardIds.length
            setIsMassnahmenMaster(storageIsMaster)
            window.localStorage.setItem('isMassnahmenMaster', JSON.stringify(storageIsMaster))
          }

          gelesenLottieRef.current = gelesenLottieRef.current.slice(0, result.length)
          setIsLoading(false)
          setAllItems(result)

          let slicedResults = result
          if (result.length > SELECTED_MAXIMUM) {
            slicedResults = result.slice(0, SELECTED_MAXIMUM)
            setAddedItemsIteration(2)
            setGridHasMore(true)
          }
          setFilteredItems(result)
          setVisibleItems(slicedResults)
          setReadCardIds(storageCardIds)
          setIsMassnahmenMaster(storageIsMaster)
        },
        error => {
          setIsLoading(false)
          console.log(error)
        }
      )
    return () => {
      controller.abort()
    }
  }, [cardAmount, SELECTED_MAXIMUM])

  useEffect(() => {
    const element: HTMLDivElement | null = sectionCardRef.current
    if (element) {
      gsap.fromTo(
        element,
        {
          opacity: 0.5,
          y: -40
        },
        {
          opacity: 1,
          y: 0,
          duration: 0.6,
          ease: Power1.easeOut,
          scrollTrigger: {
            trigger: element,
            markers: false,
            start: 'top center',
            end: 'bottom center',
            invalidateOnRefresh: true,
            scrub: false //onscroll DJ
          }
        }
      )
    }
  }, [])

  const filterItemsByCategory = (items: CardData[], category: string): CardData[] => {
    return [
      ...items.filter((card: CardData) => {
        return card.category.includes(category)
      })
    ]
  }

  const filterItemsByCost = (items: CardData[], cost: string): CardData[] => {
    return [
      ...items.filter((card: CardData) => {
        return card.cost === cost
      })
    ]
  }

  const onCategoryChange = (category: string) => {
    setIsLoading(true)

    let targetLength = addedItemsIteration * SELECTED_MAXIMUM
    const cat = category.toUpperCase()
    let filtered = []

    if (cat === '') {
      //category reset, all items
      filtered = [...allItems]
    } else {
      filtered = filterItemsByCategory(allItems, category)
    }

    setSelectedCategory(cat)
    setSelectedCost('')
    setFilteredItems(filtered)
    setVisibleItems(filtered.splice(0, targetLength))
    setGridHasMore(filtered.length > SELECTED_MAXIMUM)
    setIsLoading(false)

    //Todo: maybe move into useEffect with dependencies on Category and Cost
    massnahmenRef?.current?.scrollIntoView({ behavior: 'auto' })
  }

  const onCostChange = (cost: string) => {
    let filtered = []
    let targetLength = addedItemsIteration * SELECTED_MAXIMUM
    setIsLoading(true)

    if (cost === '') {
      //cost reset, all items
      filtered = [...allItems]
    } else {
      filtered = filterItemsByCost(allItems, cost)
    }

    setSelectedCategory('')
    setSelectedCost(cost)
    setFilteredItems(filtered)
    setVisibleItems(filtered.splice(0, targetLength))
    setGridHasMore(filtered.length > SELECTED_MAXIMUM)
    setIsLoading(false)
    massnahmenRef?.current?.scrollIntoView({ behavior: 'auto' })
  }

  const onReadCard = (card: CardData | null) => {
    if (card) {
      const newList = readCardIds
      if (!newList.includes(card.id)) {
        newList.push(card.id)
      }

      if (newList.length === cardAmount) {
        sparkleLottieRef?.current?.goToAndPlay(0, true)
      }

      setReadCardIds(newList)
      window.localStorage.setItem('readCardIds', JSON.stringify(newList))
    }
  }

  const onSparkleLottieCompleted = () => {
    if (!isMassnahmenMaster) {
      setIsTrophyModalVisible(true)
      setIsMassnahmenMaster(true)
      window.localStorage.setItem('isMassnahmenMaster', JSON.stringify(true))
    }
  }

  const handleClose = () => {
    setIsTrophyModalVisible(false)
  }

  const openInfoModal = () => {
    setIsInfoModalVisible(true)
  }

  const onGridLoadMore = () => {
    setAddedItemsIteration(addedItemsIteration + 1)
    let targetLength = addedItemsIteration * SELECTED_MAXIMUM

    if (targetLength >= filteredItems.length) {
      targetLength = filteredItems.length
      setGridHasMore(false)
    }
    let slicedItems = filteredItems.slice(0, targetLength)
    setVisibleItems(slicedItems)
  }

  const openCardModal = (card: CardData, lottieRef: any) => {
    setCurrentLottieRef(lottieRef)
    setCardModalData(card)
    setIsCardModalVisible(true)
  }

  const handleCardModalClose = () => {
    onReadCard(cardModalData)

    currentLottieRef?.current?.goToAndPlay(0, true)
    setCardModalData(null)
    setIsCardModalVisible(false)
  }

  const closeAndScroll = (id: string) => {
    if (isCardModalVisible) {
      setIsCardModalVisible(false)
      document.getElementById(id)?.scrollIntoView({ behavior: 'smooth' })
    }
  }

  return isLoading ? (
    <Spinner showLoader={isLoading} />
  ) : (
    <>
      {isInfoModalVisible && (
        <div className='massnahmen__modal'>
          <Modal
            visible={isInfoModalVisible}
            fullSize={false}
            close={() => {
              setIsInfoModalVisible(false)
            }}>
            <SectionCard
              titel={t('massnahmen.notice.headline') as string}
              description={t('massnahmen.notice.copy') as string}
            />
          </Modal>
        </div>
      )}
      <div id={props.id} className={`section section-massnahmen ${gridHasMore ? 'section-massnahmen--more' : ''}`}>
        <div className='massnahmen__blob-image'>
          <BlobMassnahmen />
        </div>

        <div className='grid-wrapper'>
          <div className='grid-container'>
            <div className='grid-cell--ten margin-lr-auto'>
              <div className='grid-container'>
                <div className='grid-cell--half'>
                  <SectionHeader
                    title={t('massnahmen.intro.title') as string}
                    subtitle={t('massnahmen.intro.headline') as string}
                    text={t('massnahmen.intro.copy') as string}
                    extendedText={t('massnahmen.intro.copy-extended') as string}
                    externalLink={t('massnahmen.intro.link-href') as string}
                    linkLabel={t('massnahmen.intro.link-label') as string}
                    hasContentExtraWidth={isMobile}
                    isHinweisVisible
                    onHinweisClick={openInfoModal}
                  />
                </div>
                <div className='grid-cell--four grid-offset-pre--two massnahmen__section-card' ref={sectionCardRef}>
                  <SectionCard
                    titel={t('massnahmen.notice.headline') as string}
                    description={t('massnahmen.notice.copy') as string}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='grid-container' ref={massnahmenRef}>
            <div className='massnahmen__header grid-cell--ten margin-lr-auto'>
              <div className='massnahmen__loader-icons d-flex justify-content-between align-items-end'>
                <Schuh />
                <Trophy />
              </div>
              <div className='massnahmen__lotti-spark'>
                <Lottie
                  animationData={sparkReadArticle}
                  lottieRef={sparkleLottieRef}
                  onComplete={onSparkleLottieCompleted}
                  autoplay={false}
                  loop={false}
                />
              </div>
              <div className='massnahmen__loader'>
                <div className='massnahmen__loader-knob' style={{ left: `${progress}%` }}>
                  <ProgressKnob />
                </div>
                <MassnahmenLoader current={readCounter} maximum={cardAmount} />
              </div>

              <div className='grid-container'>
                <div className='massnahmen__status d-flex grid-cell--half'>
                  <strong
                    dangerouslySetInnerHTML={{
                      __html: t('massnahmen.filter.status', {
                        readCounter: `<span>${readCounter}</span>`,
                        cardAmount: `<span>${cardAmount}</span>`
                      })
                    }}></strong>
                </div>

                <div className='massnahmen__filter d-flex grid-cell--half'>
                  <h5>{t('massnahmen.filter.label')}</h5>
                  <Dropdown
                    className={'w-25'}
                    label={t('massnahmen.filter.costs.label') as string}
                    placeholder={t('massnahmen.filter.costs.placeholder') as string}
                    options={costs}
                    onChange={onCostChange}
                    value={selectedCost}
                  />
                  <Dropdown
                    className={'w-25'}
                    label={t('massnahmen.filter.categories.label') as string}
                    placeholder={t('massnahmen.filter.categories.placeholder') as string}
                    options={categories}
                    value={selectedCategory}
                    onChange={onCategoryChange}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='massnahmen__filter massnahmen__filter--mobile d-flex'>
            <Dropdown
              className={'w-50'}
              label={t('massnahmen.filter.costs.label') as string}
              placeholder={t('massnahmen.filter.costs.placeholder') as string}
              options={costs}
              onChange={onCostChange}
              value={selectedCost}
            />
            <Dropdown
              className={'w-50'}
              label={t('massnahmen.filter.categories.label') as string}
              placeholder={t('massnahmen.filter.categories.placeholder') as string}
              options={categories}
              value={selectedCategory}
              onChange={onCategoryChange}
            />
          </div>

          <div className='grid-container'>
            <div className='massnahmen__grid grid-cell--twelve'>
              <Masonry
                breakpointCols={breakpointColumnsObj}
                className={`massnahmen__masonry`}
                columnClassName='massnahmen__masonry-column'>
                {visibleItems.map((card: CardData, i: number) => (
                  <div
                    className='massnahmen massnahmen-col'
                    key={card.id + '-' + i}
                    onClick={() => openCardModal(card, gelesenLottieRef.current[i])}>
                    <Card className={`massnahmen-card`}>
                      {readCardIds.includes(card.id) && (
                        <div className='massnahmen-card__read-indicator'>
                          <span>
                            <Gelesen />
                          </span>
                        </div>
                      )}
                      <div className='massnahmen-card__image'>
                        <div>
                          <img src={card.image} alt={`${card.headline} Bild`} />
                        </div>
                      </div>
                      <CardContent>
                        <ul className='massnahmen-card__category-list dfb-Card-topline'>
                          {card.category
                            .filter(cat => !excludeCategoriesFromCardDisplay.includes(cat))
                            .map(cat => (
                              <li key={cat}>
                                <span className='dfb-Card-category'>{cat}</span>
                              </li>
                            ))}
                          <li>
                            <span className='dfb-Card-category'>
                              {card.cost !== '-' ? card.cost : t('massnahmen.filter.costs.no-costs')}
                            </span>
                          </li>
                        </ul>

                        <h3 className={'dfb-Card-headline'}>{card.headline}</h3>
                        <p className={'dfb-Card-description'}>{card.description}</p>
                      </CardContent>
                      <CardFooter>
                        <Anchor
                          type={'secondary'}
                          label={t('massnahmen.filter.link') as string}
                          onClick={() => openCardModal(card, gelesenLottieRef.current[i])}
                        />
                      </CardFooter>
                      {/*{readCardIds.includes(card.id) && (*/}
                      <div className='massnahmen-card__read-state'>
                        {/*<span>*/}
                        {/*  <Gelesen/>*/}
                        {/*</span>*/}
                        {/*<h3>{t('massnahmen.filter.viewed')}</h3>*/}
                        <Lottie
                          animationData={gelesenLottie}
                          lottieRef={gelesenLottieRef.current[i]}
                          autoplay={false}
                          loop={false}
                          rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
                          style={{ height: '100%', width: '100%' }}
                        />
                      </div>
                      {/*)}*/}
                    </Card>
                    {/*{readCardIds.includes(card.id) && (*/}
                  </div>
                ))}
              </Masonry>
              {gridHasMore && (
                <div className='massnahmen__footer d-flex justify-content-center'>
                  <Button onClick={() => onGridLoadMore()} label={t('common.show-more') as string} type='primary' />
                </div>
              )}
            </div>
          </div>
          {isCardModalVisible && cardModalData && (
            <CardContentModal
              visible={isCardModalVisible}
              fullSize={isMobile}
              close={handleCardModalClose}
              title={cardModalData.fullversion.headline}
              textLeft={cardModalData.fullversion.textLeft}
              category={cardModalData.category.filter(cat => !excludeCategoriesFromCardDisplay.includes(cat))}
              cost={cardModalData.cost !== '-' ? cardModalData.cost : t('massnahmen.filter.costs.no-costs')}
              massnahmenImg={cardModalData.image}
              merkmale={cardModalData.fullversion.merkmale}
              extras={cardModalData.fullversion.extras}
              lesezeit={cardModalData.fullversion.lesezeit}
              images={cardModalData.fullversion.images}
              onSectionLink={closeAndScroll}
            />
          )}
        </div>

        <ModalTrophy visible={isTrophyModalVisible} fullSize={isMobile} close={handleClose} isMobile={isMobile} />
      </div>
    </>
  )
}

export default Massnahmen
