import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNav } from '../../hooks/useNav'
import SectionHeader from '../atom/section-header'

const SectionAnalyse = ({id}: { id: string }) => {
  const {t} = useTranslation();
  const ref = useNav(id);

  return (
    <div id={id} ref={ref}>
      <div className='section section-spiel'>
        <div className='grid-wrapper'>
          <div className='grid-container'>
            <div className='grid-cell--half grid-offset-pre--one'>
              <SectionHeader
                title={t('analyse.intro.title') as string}
                subtitle={t('analyse.intro.headline') as string}
                text={t('analyse.intro.copy') as string}
                extendedText={t('analyse.intro.copy-extended') as string}
                externalLink={t('analyse.intro.link-href') as string}
                linkLabel={t('analyse.intro.link-label') as string}
                hasHalfWidthAnimation={false}
              />
            </div>
          </div>
        </div>

        <div className='grid-wrapper'>
          <div className='grid-container'>
            <div className='section-spiel__video grid-offset-pre--two grid-offset-post--two'>
              <div className={`video-player`}>
                <div className='video-player__wrapper'>
                  <img src='/assets/images/Klimabilanztool.jpg' alt='Klimabilanztool'/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectionAnalyse
