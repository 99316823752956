import React, { useLayoutEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNav } from '../../hooks/useNav'
import { ReactComponent as Blob } from '../../assets/images/blob-anpfiff.svg';
import { ReactComponent as ScrollDown } from '../../assets/ui-icons/scroll_down.svg'
import { gsap } from 'gsap';
import { useViewport } from '../../hooks/useViewport';
import heroLottie from '../../assets/animation/hero-animation.json';
import heroLottieMobile from '../../assets/animation/hero-animation-mobile.json';
import Lottie, { LottieRef } from 'lottie-react';

const Hero = ({id, next}: { id: string; next: string }) => {
  const {t} = useTranslation()
  const ref = useNav(id)
  const {width} = useViewport();
  const blobRef = useRef<HTMLDivElement>(null)
  const heroLottieContainerRef = useRef<HTMLDivElement>(null)
  const heroLottieRef: LottieRef | undefined = useRef(null)
  const [isLottieVisible, setIsLottieVisible] = useState(false);
  const isMobile = width < 576;

  const scrollDown = () => {
    document.getElementById(next)?.scrollIntoView({behavior: 'smooth'})
  }

  useLayoutEffect(() => {
    const element: HTMLDivElement | null = blobRef.current;
    const amount = isMobile ? 5 : 10;

    setTimeout(() => {
      heroLottieContainerRef?.current?.classList.add('d-block');
      setIsLottieVisible(true);
      heroLottieRef?.current?.goToAndPlay(0, true)
    }, 5000)

    if (element) {
      gsap.fromTo(
        element,
        {
          transform: 'translate(-47%, -30%) rotate(116deg)',
        },
        {
          transform: `translate(-47%, -${30 + amount}%) rotate(116deg)`,
          scrollTrigger: {
            trigger: element,
            markers: false,
            start: '+=400px',
            end: 'bottom 40%',
            invalidateOnRefresh: true,
            scrub: true, //onscroll DJ
          },
        }
      );
    }
  }, [width, isMobile]);

  return (
    <div className='hero__container' id={id} ref={ref}>
      <div className='hero__blob-image' ref={blobRef}>
        <Blob/>
      </div>
      <div className='grid-container'>
        <div className='grid-wrapper'>
          {isLottieVisible &&
          <div className='hero__lottie'>
            <Lottie
              animationData={isMobile ? heroLottieMobile : heroLottie}
              lottieRef={heroLottieRef}
              autoplay={isLottieVisible}
              loop={true}
            />
          </div>
          }
          {/*:*/}
          <div className={`hero__text ${isLottieVisible ? 'hero__text--anim-fade' : ''}`}>
            <p className='hero__text hero__text--small'>{t('hero.smallText')}</p>
            <h1 className='h2 hero__text--main'>{t('hero.mainText')}</h1>
          </div>
          {/*}*/}
        </div>
      </div>
      <div className='hero__btn' onClick={scrollDown}>
        <ScrollDown/>
      </div>
    </div>
  )
}

export default Hero
