import React from 'react'
import PropTypes from 'prop-types'

const FooterInfoAtom = (props: any) => {
  return (
    <>
      <h4 className={`${!props.text ? 'pb-1-5' : 'h4'}`}>
        {props.title}
      </h4>
      {props.text &&  
        <p className="paragraph-big info-text">
        {props.text}
      </p>}
    </>
  )
}

export default FooterInfoAtom

FooterInfoAtom.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string
};
