import { Modal, ModalFooter, ModalContent } from '@dfb/dfb-ui-react/brands/dachmarke'
import { ModalInnerHeader } from '../atom/modal-inner-header'
import { useTranslation } from 'react-i18next'
import * as DOMPurify from 'dompurify'
import BigTrophy from '../../assets/images/trhopy-desktop.png'
import SmallTrophy from '../../assets/images/trhopy-small.png'
import React from 'react'
import SocialMediaSharing from '../atom/social-media-sharing'

export interface ModalThropy {
  visible: boolean
  fullSize: boolean
  close: void
  isMobile: boolean
  text?: string
}

export const ModalTrophy = (props: any) => {
  const { t } = useTranslation()

  const text = t('modal-trophy.text') as string

  return (
    <>
      <div className='modal-trophy'>
        <Modal visible={props.visible} fullSize={props.fullSize} close={props.close}>
          <ModalInnerHeader className='modal-trophy__header justify-content-between' isAnstossWhite />

          <ModalContent className='modal-trophy__container'>
            {props.isMobile && <img src={SmallTrophy} className='trophy__img' alt='small trophy' />}
            {!props.isMobile && <img src={BigTrophy} className='trophy__img' alt='big trophy' />}
            <div className='trophy__content'>
              <h4 className='trophy__title h4'>{t('modal-trophy.title')}</h4>
              {text &&
                text.split('{{ABSATZ}}').map((paragraph, index) => (
                  <p
                    key={index}
                    className='trophy__text'
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(paragraph, { USE_PROFILES: { html: true } })
                    }}></p>
                ))}
            </div>
          </ModalContent>
          <ModalFooter className='modal-trophy__footer'>
            <SocialMediaSharing title={props.title} />
          </ModalFooter>
        </Modal>
      </div>
    </>
  )
}
